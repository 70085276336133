import Vue from 'vue';
import Router from 'vue-router';
import { whatsappRoutes } from './whatsapp.routes';

const defaultLayout = () => import('@/layouts/DefaultLayout.vue');
const errorLayout = () => import('@/layouts/ErrorLayout.vue');
const loggedOutLayout = () => import('@/layouts/LoggedOutLayout.vue');

Vue.use(Router);

export const routes = [
    //agency routes
    {
        path: '/',
        meta: { requiresAuth: true, requiresAgency: true },
        component: defaultLayout,
        children: [
            {
                path: '',

                name: 'home-page',
                component: () => import('@/components/AgencyDashboard.vue'),
            },
            {
                path: '/academy',
                name: 'academy',
                component: () => import('@/views/Academy.vue'),
                children: [
                    {
                        path: ':section',
                        name: 'academy-module',
                        children: [
                            {
                                path: ':videoId',
                                name: 'academy-video',
                            },
                        ],
                    },
                ],
            },
            {
                path: '/settings',
                component: () => import('@/layouts/SettingsLayout.vue'), // This should be a layout component with a <router-view></router-view> inside.
                children: [
                    {
                        //no path
                        path: '',
                        name: 'settings',
                        component: () => import('@/views/settings/Account.vue'),
                    },
                    {
                        path: 'account',
                        name: 'settings-account',
                        component: () => import('@/views/settings/Account.vue'),
                    },
                    {
                        path: 'branding',
                        name: 'settings-branding',
                        component: () => import('@/views/settings/Branding.vue'),
                    },
                    {
                        path: 'privacy',
                        name: 'settings-privacy',
                        component: () => import('@/views/settings/Privacy.vue'),
                    },
                    {
                        path: 'billing',
                        name: 'settings-billing',
                        component: () => import('@/views/settings/Billing.vue'),
                    },
                    {
                        path: 'subscription',
                        name: 'settings-subscription',
                        component: () => import('@/views/settings/Subscription.vue'),
                    },
                    {
                        path: 'subusers',
                        name: 'settings-subusers',
                        component: () => import('@/views/settings/Subuser.vue'),
                    },
                ],
            },
            {
                path: '/referral',
                name: 'referral',
                component: () => import('@/views/Referral.vue'),
            },
            whatsappRoutes,
        ],
    },
    //noauth routes
    {
        path: '/customer/:customerID/login',
        meta: { requiresAuth: false, requiresAgency: false },
        props: true,
        component: () => import('@/layouts/LoggedOutLayout.vue'),
        children: [
            {
                path: '',
                name: 'customerLogin',
                component: () => import('@/views/CustomerSignIn.vue'),
            },
        ],
    },
    {
        path: '/customer/:customerID/signin',
        meta: { requiresAuth: false, requiresAgency: false },
        props: true,
        component: () => import('@/layouts/LoggedOutLayout.vue'),
        children: [
            {
                path: '',
                name: 'customerSignin',
                component: () => import('@/views/CustomerSignIn.vue'),
            },
        ],
    },

    {
        path: '/customer/',
        meta: {
            requiresAuth: true,
            reroutesToCustomerLogin: true,
            requiresAgency: false,
        },
        props: true,
        component: defaultLayout,
        children: [
            {
                path: ':customerID',
                component: () => import('@/views/CustomerView.vue'),
                children: [
                    {
                        path: 'dashboard',
                        component: () => import('@/views/CustomerDashboardView.vue'),
                    },
                    {
                        path: 'table/:tableID',
                        component: () => import('@/views/table/Base.vue'),
                        children: [
                            {
                                path: '',
                                component: () => import('@/views/TableView.vue'),
                            },
                            {
                                path: 'settings',
                                component: () => import('@/views/table/Settings.vue'),
                            },
                            {
                                path: 'setup',
                                component: () => import('@/views/table/Setup.vue'),
                            },
                            {
                                path: 'email',
                                component: () => import('@/views/table/Email.vue'),
                                children: [
                                    {
                                        path: '',
                                        component: () => import('@/views/table/email/Automation.vue'),
                                    },
                                    {
                                        name: 'emailAutomations',
                                        path: 'automations',
                                        component: () => import('@/views/table/email/Automation.vue'),
                                    },
                                    {
                                        path: 'connections',
                                        component: () => import('@/views/table/email/Connections.vue'),
                                    },
                                    {
                                        path: 'templates',
                                        component: () => import('@/views/table/email/Templates.vue'),
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'settings',
                        component: () => import('@/views/CustomerSettings.vue'),
                    },
                    {
                        path: 'conversations',
                        component: () => import('@/views/CustomerConversations.vue'),
                    },
                ],
            },
        ],
    },
    //Logged out
    {
        path: '/',
        component: loggedOutLayout,
        children: [
            {
                path: '/signin',
                name: 'signin-page',
                component: () => import('@/views/SignIn.vue'),
            },
            {
                path: '/signup',
                name: 'signup-page',
                component: () => import('@/views/SignUp.vue'),
            },
        ],
    },

    //unauth routes
    {
        path: '/',
        component: loggedOutLayout,
        children: [
            {
                path: '/verify/:magicLink',
                name: 'verify-page',
                component: () => import('@/views/Onboarding.vue'),
            },
            {
                path: '/resetPassword/:token',
                name: 'reset-password-page',
                component: () => import('@/views/ResetPassword.vue'),
            },
            {
                path: '/setCustomerPassword',
                name: 'customerlogin-password-page',
                component: () => import('@/views/CustomerSetPassword.vue'),
            },
            {
                path: '/subuserOnboarding',
                name: 'subuser-password-page',
                component: () => import('@/views/ResetSubuserPassword.vue'),
            },

            {
                path: '/onboarding',
                component: () => import('@/views/Onboarding.vue'),
            },
            {
                path: '/verificationSent/:email',
                name: 'verification-send-page',
                component: () => import('@/views/VerificationSent.vue'),
            },
            {
                path: '/impressum',
                name: 'impressum-page',
                component: () => import('@/views/Impressum.vue'),
            },
            {
                path: '/datenschutz',
                name: 'datenschutz-page',
                component: () => import('@/views/Datenschutz.vue'),
            },
            {
                path: '/AGBs',
                name: 'AGB-page',
                component: () => import('@/views/AGBs.vue'),
            },
        ],
    },
    {
        path: '/customer/:customerID/table/:tableID/unsubscribe/:token',
        name: 'unsubscribe-page',
        component: () => import('@/views/Unsubscribe.vue'),
    },
    {
        path: '/privacynotice',
        component: () => import('@/layouts/DefaultLayout.vue'),
        children: [
            {
                path: '',
                name: 'privacy-notice',
                component: () => import('@/views/PrivacyNotice.vue'),
            },
        ],
    },
    {
        path: '/404',
        component: () => import('@/layouts/ErrorLayout.vue'),
        children: [
            {
                path: '/404',
                name: '404',
                component: () => import('@/views/error/ServerError.vue'),
            },
            {
                path: '*',
                name: 'error',
                component: () => import('@/views/error/NotFoundPage.vue'),
            },
        ],
    },
    {
        path: '*',
        name: 'not-found',
        component: () => import('@/layouts/ErrorLayout.vue'),
        children: [
            {
                path: '',
                name: 'error',
                component: () => import('@/views/error/NotFoundPage.vue'),
            },
        ],
    },
];

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL || '/',
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        } else if (to.hash) {
            return {
                selector: to.hash,
            };
        } else {
            return { x: 0, y: 0 };
        }
    },
    routes,
});

export default router;
