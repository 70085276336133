<template>
    <media-controller audio :class="cssClass">
        <audio slot="media" :src="src"></audio>

        <media-control-bar class="audio-player__controls">
            <media-play-button class="audio-player__play"></media-play-button>

            <media-time-display showduration></media-time-display>

            <div class="audio-player__volume-controls">
                <media-mute-button class="audio-player__volume-mute"></media-mute-button>
                <media-volume-range class="audio-player__volume-range"></media-volume-range>
            </div>

            <media-time-range class="audio-player__timeline"></media-time-range>
        </media-control-bar>
    </media-controller>
</template>

<script>
export default {
    props: {
        src: {
            type: String,
            required: true,
        },
        modifer: {
            type: String,
            required: false,
        },
    },
    computed: {
        cssClass() {
            return {
                'audio-player': true,
                [`audio-player--${this.modifer}`]: !!this.modifer,
            };
        },
    },
};
</script>

<style scoped lang="scss">
$controlls-border-radius: 6px;

@mixin controllButton {
    padding: 5px;
    &:hover {
        // background-color: #ccc;
    }
}

.audio-player {
    $self: &;

    // button
    --media-button-icon-width: 30px;
    --media-button-icon-height: 20px;

    // range track
    --media-range-track-height: 8px;
    --media-range-track-border-radius: 999px;
    --media-range-track-background: #e8e9eb;

    // range thumb
    --media-range-thumb-width: 16px;
    --media-range-thumb-height: 16px;
    --media-range-thumb-background: white;
    --media-range-thumb-border: 2px solid var(--v-primary-base);
    --media-range-bar-color: var(--v-primary-base);
    --media-time-range-buffered-color: #d0d5dd;

    // tooltip
    --media-tooltip-display: none;

    // other
    --media-icon-color: #667085;
    --media-control-background: transparent;
    --media-control-hover-background: transparent;
    --media-background-color: transparent;
    --media-text-color: var(--media-icon-color);

    // css
    width: 100%;
    display: block;

    &__youtube {
    }

    &__thumbnail {
        object-fit: fill;
    }

    // controlls
    &__controls {
        border-radius: $controlls-border-radius;
        background-color: white;
        width: 100%;
    }

    &__play {
        @include controllButton;
    }

    &__volume-controls {
        @include controllButton;

        position: relative;
        display: flex;
        align-items: center;
        width: fit-content;
        flex-shrink: 0;
        transition: 0.2s;

        &:hover {
            #{ $self }__volume-range {
                display: block;
            }
        }
    }

    &__volume-mute {
        flex-shrink: 0;
        transition: 0.2s;
    }

    &__volume-range {
        display: none;

        position: absolute;
        top: 0;
        left: 2px;
        width: 100px;
        padding-right: 16px;
        margin-left: -5px;
        margin-top: 5px;
        background-color: white;
        border-radius: $controlls-border-radius;
        rotate: -90deg;
        transform-origin: top left;
        transition: 0.2s;
        z-index: 99;
    }

    &__timeline {
        flex-grow: 1;
        transition: 0.2s;
        padding-right: 12px;
    }

    &__fullscreen {
        @include controllButton;
    }

    &--transparent {
        #{ $self }__controls {
            background-color: transparent;
        }
    }
}
</style>
