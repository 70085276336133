const possibilities = {
    Name: ['vorname', 'nachname', 'zuname', 'heißt', 'name', 'heißen', 'prénom', 'nom de famille', 'név', 'ime'],
    Telefonnummer: ['telefonnummer', 'handynummer', 'mobilnummer', 'telefon', 'handy', 'handynummer', 'phone'],
    'E-Mail': ['email', 'emailadresse', 'mailadresse', 'mail', 'emajl'],
};

module.exports = (profile) => {
    const filtered = {
        name: {
            key: '--',
            value: '--',
        },
        phone: {
            key: '--',
            value: '--',
        },
        email: {
            key: '--',
            value: '--',
        },
    };
    if (!profile) return filtered;
    for (const key in profile) {
        const value = profile[key].value;
        //skip empty values
        if (!value) continue;
        const title = profile[key].title || '';

        const lowerCaseCleaned = title.toLowerCase().replace(/-|:|\?/g, '');
        for (const nameOption of possibilities['Name']) {
            if (lowerCaseCleaned.search(nameOption) !== -1) {
                if (filtered.name.value === '--') {
                    filtered.name.value = '';
                }
                //if name option is ime, check if it is not part of a word like time

                if (
                    nameOption === 'ime' &&
                    !title.split(' ').some(function (w) {
                        return w === 'ime';
                    })
                ) {
                    //skip
                } else {
                    filtered.name.value += value + ' ';
                    filtered.name.key = title;
                }
                break;
            }
        }

        for (const phoneOption of possibilities['Telefonnummer']) {
            if (typeof value === 'undefined' || value === null) {
                continue;
            }

            // Check if the value contains any letters
            const containsLetters = /[a-zA-Z]/.test(value);
            const isValidLength = value.length >= 5 && value.length <= 20;
            if (lowerCaseCleaned.search(phoneOption) !== -1) {
                if (!containsLetters && isValidLength) {
                    filtered.phone.value = value;
                    filtered.phone.key = title;
                    break;
                }
            }
        }

        for (const mailOption of possibilities['E-Mail']) {
            if (
                lowerCaseCleaned.search(mailOption) !== -1 &&
                String(value).includes('@') &&
                String(value).includes('.')
            ) {
                filtered.email.value = value;
                filtered.email.key = title;
                break;
            }
        }
    }

    filtered.name.value = filtered.name.value.slice(0, -1);

    return filtered;
};
