import store from '../../../store';
import router from '../../../router';

export function updateCustomerSidebar(customerID) {
    const currentRoute = router.currentRoute;
    const isCustomerRoute = currentRoute.matched.some((record) => record.path.includes('/customer/'));
    const hasWhatsAppAccess = store.getters.hasWhatsAppAccess();

    // If not in a customer route, return
    if (!isCustomerRoute) return;

    // if not in the correct customer, return
    if (currentRoute.params.customerID !== customerID) return;

    let sidebar = [
        {
            paddingTop: true,
            name: 'ADMIN',
            divider: false,
            customerIcon: true,
            items: [
                { type: 'link', title: 'Kunden-Übersicht', icon: '$arrowLeft', href: `/` },
                {
                    type: 'link',
                    title: 'Kundeneinstellungen',
                    icon: '$userEdit',
                    href: `/customer/${customerID}/settings`,
                },
            ],
        },
    ];

    // Check if the decoded token has agency true
    if (!store.getters.isAgency()) {
        sidebar = [];
    }
    const tablesOfCustomer = store.getters['customer/getTablesByCustomerID'](customerID)
        .filter((e) => {
            return e.ownerCustomer === customerID && !e.archived;
        })
        .sort((a, b) => {
            return a.occupation.localeCompare(b.occupation);
        });
    const campaigns = tablesOfCustomer.map((e) => {
        const removePrequalify = !store.getters.isAgency() && e.prequalify;
        let leadsInUnused = 0;
        if (removePrequalify) {
            leadsInUnused = e.leadsUnused;
        }
        return {
            type: 'campaign',
            title: e.occupation,
            href: `/customer/${customerID}/table/${e._id}`,
            campaignID: e._id,
            leads: {
                new: e.leadsNew,
                total: e.leadsCount - leadsInUnused,
            },
        };
    });

    sidebar.push({
        paddingTop: true,
        name: 'KUNDE',
        divider: false,
        items: [
            {
                type: 'link',
                title: 'Nachrichten',
                icon: '$whatsapp',
                href: `/customer/${customerID}/conversations`,
                hidden: !hasWhatsAppAccess,
                isConversationItem: true,
            },
            {
                type: 'link',
                title: 'Kampagnen',
                icon: '$grid01',
                href: `/customer/${customerID}/dashboard`,
            },
            ...campaigns,
        ],
    });
    store.commit('customer/SET_SIDEBAR', sidebar);
}
