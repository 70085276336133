import Vue from 'vue';
import axios from 'axios';

// Create the axios instance
const apiClient = axios.create({
    baseURL: '/api/', // Base URL for your backend
    timeout: 30000,
    // headers: {
    //     'Content-Type': 'application/json',
    // },
});

// Function to dynamically set the Authorization token
export const setAuthToken = (token) => {
    if (token) {
        apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete apiClient.defaults.headers.common['Authorization'];
    }
};

// Make the axios instance available as $api in all components
Vue.prototype.$api = apiClient;

export default apiClient;
