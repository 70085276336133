export const whatsappRoutes = {
    path: '/whatsapp',
    name: 'whatsapp',
    component: () => import('@/app/whatsapp/views/WhatsAppLayout.vue'),
    children: [
        {
            path: '',
            redirect: { name: 'whatsapp-chat' }
        },
        {
            path: 'chat',
            name: 'whatsapp-chat',
            component: () => import('@/components/ChatOverview.vue'),
        },
        {
            path: 'templates',
            name: 'whatsapp-templates',
            component: () => import('@/app/whatsapp/components/WhatsAppTemplates.vue'),
        },
        {
            path: 'connections',
            name: 'whatsapp-connections',
            component: () => import('@/app/whatsapp/components/WhatsAppConnections.vue'),
        },
    ],
};
